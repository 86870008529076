<template>
  <div class="mycreation">
    <div style="height: 5.625rem"></div>
    <!-- 创作 -->
    <div class="pt-50 pb-40 flex flex-column align-center justify-center">
      <div class="font-26 text-222222 mb-20">创作</div>
      <div class="flex align-center">
        <div class="mr-15 cursorp" @click="goMycreation">
          <!-- <img
            src="@/assets/img/mywork.png"
            alt=""
            width="7.8125rem"
            height="2.1875rem"
          /> -->
          <el-image
            :src="require('@/assets/img/mywork.png')"
            style="width: 7.8125rem; height: 2.1875rem"
            fit="cover"
          ></el-image>
        </div>
        <div class="cursorp addchuangzuo" @click="goupwork">
          加入创作联盟
          <!-- <img src="@/assets/img/upvideo.png" alt="" width="6.25rem" height="2.1875rem" /> -->
        </div>
      </div>
    </div>
    <!-- 内容 -->
    <div class="px-315 bg-F1F2F3 pt-35 pb-90 flex">
      <!-- 我的作品 -->
      <div
        class="mr-20 bg-white px-15 pt-30 pb-65"
        style="height: -webkit-fill-available"
      >
        <div class="font-20 text-222222 mb-30">我的作品</div>
        <div class="bg-img1">
          <div class="font-14 text-666666 mb-10">播放量</div>
          <div class="font-32 text-222222 font-weight-bolder">
            {{ info.play_nums }}
          </div>
        </div>
        <div class="bg-img2">
          <div class="font-14 text-666666 mb-10">收到的赞</div>
          <div class="font-32 text-222222 font-weight-bolder">
            {{ info.fabulous_nums }}
          </div>
        </div>
      </div>
      <!-- 作品管理 -->
      <div class="comment_box pt-35 pb-55 bg-white x-1">
        <div class="px-30 pb-50">
          <div class="flex align-center justify-between mb-30">
            <div class="font-20">作品管理</div>
            <div @click="manage" v-if="!flag" class="cursorp">
              <!-- <img
                src="@/assets/img/guanli.png"
                width="1.3125rem"
                height="1.3125rem"
                alt=""
              /> -->
              <el-image
                :src="require('@/assets/img/guanli.png')"
                style="width: 1.3125rem; height: 1.3125rem"
                fit="cover"
              ></el-image>
              <span class="ml-10 font-14">管理</span>
            </div>
            <div @click="manage" v-else class="cursorp">
              <span class="ml-10 font-14">取消</span>
            </div>
          </div>
          <div class="flex align-center lable mb-40">
            <div
              v-for="(item, index) in lableList"
              :key="index"
              class="lable_li cursorp"
              :class="item.id == state ? 'lable_liact' : ''"
              @click="lablecut(item.id)"
            >
              {{ item.title }}
            </div>
          </div>
          <div class="comment_box_cont" @scroll="handleScroll($event)">
            <div
              v-for="(item, index) in cardList"
              :key="index"
              class="flex justify-between align-center mb-30 cursorp"
              @click="godetails(item)"
            >
              <div class="flex align-center">
                <div style="width: 11.875rem; height: 6.5625rem">
                  <el-image
                    class="rounded10"
                    style="width: 11.875rem; height: 6.5625rem"
                    :src="item.img"
                    fit="cover"
                  ></el-image>
                </div>
                <!-- <img
                  :src="item.img"
                  width="11.875rem"
                  height="6.5625rem"
                  class="rounded10"
                  alt=""
                /> -->
                <div class="ml-15">
                  <div class="mb-15 font-14">
                    {{ item.title }}
                  </div>
                  <div class="mb-30 font-14 text-CCCCCC">
                    {{ item.create_time }}
                  </div>
                  <div v-if="item.state == 1" class="font-14 text-999999">审核中</div>
                  <div v-if="item.state == 2" class="font-14 text-999999">
                    <span class="mr-10">播放量{{ item.play_nums }}</span>
                    <span class="mr-10">评论{{ item.comment_nums }}</span>
                    <span>赞{{ item.fabulous_nums }}</span>
                  </div>
                  <div v-if="item.state == 3" class="font-14 text-999999">
                    已驳回（{{ item.remark }}）
                  </div>
                </div>
              </div>
              <div v-if="flag" @click.stop="seleBtn(item, index)">
                <!-- <img
                  src="@/assets/img/sele_act.png"
                  alt=""
                  width="1.625rem"
                  height="1.625rem"
                  v-if="item.flag"
                /> -->
                <el-image
                  :src="require('@/assets/img/sele_act.png')"
                  style="width: 1.625rem; height: 1.625rem"
                  fit="cover"
                  v-if="item.flag"
                ></el-image>
                <!-- <img
                  src="@/assets/img/sele.png"
                  alt=""
                  width="1.625rem"
                  height="1.625rem"
                  v-else
                /> -->
                <el-image
                  :src="require('@/assets/img/sele.png')"
                  style="width: 1.625rem; height: 1.625rem"
                  fit="cover"
                  v-else
                ></el-image>
              </div>
            </div>
            <!-- <div class="font-14 text-666666 text-center">
              {{ bottomtext }}
            </div> -->
          </div>
        </div>
        <div class="flex font-20 text-center py-20 border-top border-F2F2F2" v-if="flag">
          <div class="x-2 border-right border-DDDDDD cursorp" @click="allsele">全选</div>
          <div class="x-2 text-FF524C cursorp" @click="delesele">删除</div>
        </div>
        <div
          class="flex align-center justify-center py-20 border-top border-ligit-BFBFBF"
        >
          <!-- :total="1000" -->
          <el-pagination
            background
            layout="prev, pager, next"
            prev-text="上一页"
            next-text="下一页"
            :page-count="page_nums"
            @current-change="currentChange"
            :current-page="page"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import req from "../../utils/req";
export default {
  data() {
    return {
      page_nums: 0,
      page: 1,
      flag: false,
      lableList: [
        {
          id: "",
          title: "全部",
        },
        {
          id: 2,
          title: "已发布",
        },
        {
          id: 1,
          title: "发布中",
        },
        {
          id: 3,
          title: "发布失败",
        },
      ],
      info: "",
      state: "",
      page: 1,
      cardList: [],
      bottomtext: "滑动加载更多~",
    };
  },
  mounted() {
    this.page = 1;
    this.getdata();
  },
  // computed: {
  //   getCarts() {
  //     for (let i = 0; i < this.cardList.length; i++) {
  //       this.cardList[i] = Object.assign({}, this.cardList[i], { editState: false });
  //     }
  //     this.cardList = Object.assign({}, this.cardList);
  //     return this.cardList;
  //   },
  // },
  methods: {
    currentChange(e) {
      console.log(e);
      this.page = e;
      this.getdata();
    },
    // 跳转上传作品
    goupwork() {
      // this.$router.push({
      //   path: "/upwork",
      //   query: { type: "upwork" },
      // });
      this.$router.push({
        path: "/richtext",
        query: {type: "richtext", foottype: 8 },
      });
    },
    // 删除作品
    delesele() {
      let that = this;
      let arr = [];
      if (that.cardList && that.cardList.length) {
        that.cardList.forEach((item) => {
          if (item.flag) {
            arr.push(item.id);
          }
        });
      }
      let str = arr.join(",");
      console.log(str);
      this.$confirm("是否删除此作品?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          req
            .post("original/delOriginal", {
              id: str,
            })
            .then((res) => {
              if (res.code != 200) {
                that.$message.error(res.msg);
                return;
              }
              that.$message.success(res.msg);
              that.page = 1;
              that.cardList = [];
              that.getdata();
            })
            .catch((err) => {
              console.log(err);
              that.$message.error(err.msg);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 全选
    allsele() {
      for (let i = 0; i < this.cardList.length; i++) {
        let obj = this.cardList[i];
        obj.flag = true;
        this.cardList.splice(i, 1, obj);
      }
    },
    // 切换标签
    lablecut(id) {
      this.state = id;
      this.page = 1;
      this.cardList = [];
      this.getdata();
    },
    // 选择作品
    seleBtn(item, i) {
      let obj = this.cardList[i];
      obj.flag = !obj.flag;
      this.cardList.splice(i, 1, obj);
    },
    // 作品管理
    manage() {
      this.flag = !this.flag;
      if (this.cardList && this.cardList.length) {
        this.cardList.forEach((item) => {
          item.flag = false;
        });
      }
    },
    // 跳转详情
    godetails(e) {
      // console.log(e);creationdetails
      if (e.state == 2) {
        // this.$router.push({
        //   path: "/creationdetails",
        //   query: { type: "creationdetails", moveid: e.id },
        // });
        this.$router.push({
        path: "/creationDetail2",
        query: { type: "creationDetail2", moveid: e.id },
      });
      } else if (e.state == 1) {
        this.$message("正在审核中请耐心等待~");
      } else {
        this.$router.push({
          path: "/upwork",
          query: { type: "upwork", moveid: e.id },
        });
      }
    },
    // 跳转我的创作
    goMycreation() {
      this.$router.push({
        path: "/creationMy",
        query: { type: "creationMy" },
      });
    },
    // 获取页面滚动距离
    handleScroll(e) {
      // let el = e.target;
      // //判断是否到达div容器底部
      // if (el.scrollTop + el.clientHeight >= el.scrollHeight) {
      //   //控制页数
      //   this.page = this.page + 1;
      //   //调用后台接口
      //   this.getdata();
      // }
    },
    // 获取数据
    getdata() {
      let that = this;
      req
        .post("original/myOriginal", {
          state: this.state,
          page: this.page,
          per_page: 10,
        })
        .then((res) => {
          if (res.code != 200) {
            that.$message.error(res.msg);
            return;
          }
          this.page_nums = res.data.original.last_page;
          this.info = res.data;
          this.bottomtext = "滑动加载更多~";
          if (res.data.original.data && res.data.original.data.length) {
            res.data.original.data.forEach((item) => {
              item.flag = false;
            });
          }
          if (res.data.original.data && res.data.original.data.length) {
            if (this.page == 1) {
              this.cardList = res.data.original.data;
            } else {
              this.cardList = [...this.cardList, ...res.data.original.data];
            }
          } else {
            this.bottomtext = "没有更多数据~";
          }
        })
        .catch((err) => {
          console.log(err);
          that.$message.error(err.msg);
        });
    },
  },
};
</script>

<style lang="scss">
.addchuangzuo {
  background: linear-gradient(0deg, #ff534d, #ff8f6d);
  border-radius: 6.25rem;
  padding: 0 0.9375rem;
  font-size: 0.875rem;
  line-height: 2.1875rem;
  color: #ffffff;
}
.mycreation {
  .comment_box {
    &_cont {
      overflow: auto;
      height: 31.25rem;
    }
    &_cont::-webkit-scrollbar {
      display: none;
    }
  }
  .lable {
    &_li {
      width: 7.6875rem;
      height: 2.1875rem;
      border-radius: 0.25rem;
      background: #e9e9e9;
      line-height: 2.1875rem;
      font-size: 0.875rem;
      color: #222222;
      text-align: center;
      margin-right: 0.75rem;
    }
    &_liact {
      background: linear-gradient(0deg, #ff534d, #ff8f6d);
      color: #ffffff;
    }
  }
  .bg-img1 {
    width: 15rem;
    height: 5.5rem;
    background-image: url("../../assets/img/playnum.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin-bottom: 1.125rem;
    padding: 1.4375rem 0 1rem 1.5rem;
  }
  .bg-img2 {
    width: 15rem;
    height: 5.5rem;
    background-image: url("../../assets/img/likenum.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 1.4375rem 0 1rem 1.5rem;
  }
}
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #e7524c !important;
}
</style>
